import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import soldSign from "../images/sold-sign.jpg";
import hammock from "../images/family/Hammock.jpg";
import alaska1 from "../images/family/Alaska2Sm.jpg";
import alaska2 from "../images/family/Alaska4Sm.jpg";
import hawaii1 from "../images/family/Hawaii1Sm.jpg";
import hawaii2 from "../images/family/Hawaii3Sm.jpg";
import newYork from "../images/family/NewYorkSm.jpg";

function IndexPage() {
  return (
    <Layout title="About Jonathan">
      <SEO title="About Jonathan" />

      <section>
        <div className="max-w-6xl mx-auto pb-3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="md:mt-0">
              <div className="flex justify-center">
                <img alt="Sold Sign" src={soldSign} className="border-2 border-black rounded object-contain h-80" />
              </div>
            </div>
            <div>
              <p className="leading-relaxed">
                Jonathan Goforth has SOLD hundreds of homes over his successful 26-year career, selling an impressive $36.5 Million in 2021 and $39 Million in 2022. He was listed in <b>Forbes Magazine</b> in 2019, 2020 and 2021 representing Kansas City as one of the &quot;Top Market Leaders in Real Estate in the Nation.&quot;  He was listed in <b>Fortune Magazine</b> in 2021 on the &quot;Top 500 List&quot; nationally.  Those articles were published in Fortune and Forbes Magazine by Five-Star Professionals. Jonathan is a published author of the book: HOW TO SELL HOMES IN A TOUGH MARKET, which is available at Barnes & Noble, Amazon and on Kindle.
              </p>
            </div>
          </div>
        </div>
      </section>

      <p className="leading-relaxed">
        Jonathan is hosting the new TV Show &quot;SELLING KANSAS CITY&quot; produced by the Emmy-Nominated American Dream.  It airs on KCWE TheCW, Roku, AppleTV, AmazonFire and FOX.  This new hit TV Show has received numerous Telly Awards in 2023 as well as multiple Emmy nominations in 2022 and 2023.
      </p>
      <p className="leading-relaxed pt-3">
        Jonathan has a strong following on social media with thousands of subscribers on YouTube and nearly a million views.  <a className="text-blue-500 cursor-pointer" href="https://www.youtube.com/@jonathangoforthshow7427" target="_blank" rel="noopener noreferrer">Visit his YouTube channel:  &quot;Jonathan Goforth Show.&quot;</a>
      </p>

      <section className="pt-3">
        <div className="flex">
          <div className="w-1/2"><img src={alaska1} alt="Family Alaska" className="border-2 border-black rounded object-contain" /></div>
          <div className="w-1/2"><img src={alaska2} alt="Family Alaska" className="border-2 border-black rounded object-contain" /></div>
        </div>
      </section>

      <section>
        <p className="leading-relaxed pt-3">
          Jonathan spent his first 18 years with ReeceNichols Realtors where he rose to be one of the top-selling Realtors in Kansas City and enjoyed speaking at numerous events. He moved to Keller Williams Platinum Partners a few years ago and created the Goforth Team with an impressive lineup of real estate agents.  <Link to='/meet-the-team' className="text-blue-500 cursor-pointer">Please see &quot;Meet The Team&quot;</Link>.
        </p>
      </section>

      <section className="pt-3">
        <div className="grid grid-rows-1 grid-flow-col gap-2">
          <div><img src={hammock} alt="Hammock" className="border-2 border-black rounded object-contain" /></div>
          <div><img src={hawaii1} alt="Hawaii" className="border-2 border-black rounded object-contain" /></div>
          <div><img src={hawaii2} alt="Hawaii" className="border-2 border-black rounded object-contain" /></div>
        </div>
      </section>

      <section>
        <p className="leading-relaxed pt-3 pb-3">
        Jonathan graduated from Missouri State University with a degree in Business & Marketing. He played trombone in the Bruin Pride Marching Band during college. He and his wife, Carrie, live in Lee&apos;s Summit with their three children. Jonathan was ordained in the ministry as a Deacon in 2009 at First Baptist Raytown (Connection Point Church) and served as Chairman of Deacons as well as serving on numerous church committees. They now attend Abundant Life Church in Lee&apos;s Summit.
        </p>
      </section>

      <section>
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
            <div className="md:mt-0">
              <div className="flex justify-center">
                <img alt="New York" src={newYork} className="border-2 border-black rounded object-contain h-80" />
              </div>
            </div>
            <div>
              <p className="leading-relaxed pb-3">{`The Goforth Team has won numerous awards in both sales and excellence in customer service and is one of the most successful teams in greater Kansas City.`}</p>
              <p className="leading-relaxed">Contact Jonathan directly at <b>816-830-7575</b> for your real estate needs!</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
